























import {
  computed, defineComponent, inject, onMounted, PropType, ref, useRoute,
} from '@nuxtjs/composition-api';
import { SfCheckbox } from '@storefront-ui/vue';
import type { Aggregation } from '~/modules/GraphQL/types';
import Icon from '~/almarwan/components/Icon.vue';

export default defineComponent({
  components: {
    SfCheckbox
  },
  props: {
    filter: {
      type: Object as PropType<Aggregation>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { isFilterSelected } = inject('UseFiltersProvider');
    const isSubcategoriesActive = ref(false)
    const isInUrl = ref(false)
    const showAll = ref(false)
    const selected = computed(() => ((id: string, optVal: string) => isFilterSelected(id, optVal)));
    const route = useRoute()
    const filterOption = (filter) => filter?.options?.length
    const navigate = (option) => {
      emit('selectFilter', option)
    }

    const filterSelected = (filter) => {
      isInUrl.value = Boolean(filter in route.value.query)
    }

    const filterDrop = () => {
      isSubcategoriesActive.value = !isSubcategoriesActive.value
      isInUrl.value = false
    }

    const typeLabel = () => {
      if (route.value.path.includes('equipment')){
        return 'Equipment Type'
      }
      if (route.value.path.includes('attachment')){
        return 'Attachment Type'
      }
      return 'Types';
    }
    const toggleShowAll = () => showAll.value = !showAll.value
    const filteredOptions = computed(() => showAll.value ? props.filter.options : props.filter.options.slice(0, 5));
    onMounted(() => {
      filterSelected(props?.filter?.attribute_code)
    })

    return {
      typeLabel,
      isSubcategoriesActive,
      isInUrl,
      filterDrop,
      filterSelected,
      navigate,
      selected,
      filterOption,
      toggleShowAll,
      filteredOptions,
      showAll
    };
  },
});
